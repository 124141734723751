/**
 * @license
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { __assign } from "tslib";
import { ListenEvent } from '../interfaces';
import { fromRef } from '../fromRef';
import { map } from 'rxjs/operators';
/**
 * Get the snapshot changes of an object
 * @param query
 */
export function object(query) {
    return fromRef(query, ListenEvent.value);
}
/**
 * Get an array of object values, optionally with a mapped key
 * @param query object ref or query
 * @param keyField map the object key to a specific field
 */
export function objectVal(query, options) {
    if (options === void 0) { options = {}; }
    return fromRef(query, ListenEvent.value).pipe(map(function (change) { return changeToData(change, options); }));
}
export function changeToData(change, options) {
    var _a;
    if (options === void 0) { options = {}; }
    var val = change.snapshot.val();
    // match the behavior of the JS SDK when the snapshot doesn't exist
    if (!change.snapshot.exists()) {
        return val;
    }
    // val can be a primitive type
    if (typeof val !== 'object') {
        return val;
    }
    return __assign(__assign({}, val), (options.keyField ? (_a = {}, _a[options.keyField] = change.snapshot.key, _a) : null));
}
